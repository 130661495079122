import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import DashboardView from './views/DashboardView'
import ProfileView from './views/ProfileView'
import CreateModeratorView from './views/CreateModeratorView'
import { UserProvider } from './UserContext/UserContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/dashboard" element={<DashboardView />} />
          <Route path="/profile" element={<ProfileView />} />
          <Route path="/create-moderator" element={<CreateModeratorView />} />
        </Routes>
      </Router>
      <ToastContainer />
    </UserProvider>
  )
}

export default App