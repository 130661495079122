export const IcoVoiceAudio = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.25 10.27v4.47M9.375 8.781v7.438M12.5 7.292v10.416M15.625 8.781v7.438M18.75 10.27v4.47M12.5 22.917c5.753 0 10.417-4.664 10.417-10.417S18.253 2.083 12.5 2.083 2.083 6.747 2.083 12.5 6.747 22.917 12.5 22.917z'
      ></path>
    </svg>
  )
}
