import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { Box, Button, Card, TextField } from '@mui/material'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import { IcoMessageQuede } from '../ui/icons'
import Title from '../ui/Title'

import AudioPlayer from './AudioPlayer'
import { fetchAddMessageToQueue, fetchQueueMessages } from '../../data/apiService'
import { useUser } from '../../UserContext/UserContext'

const MessageQueue = forwardRef((props, ref) => {
  const [queue, setQueue] = useState([])
  const audioPlayerRef = useRef(null)
  const [message, setMessage] = useState('')
  const { user } = useUser()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (message && user) {
      try {
        await fetchAddMessageToQueue(user, message)
        console.log('Message added to queue successfully')
        setMessage('')
      } catch (error) {
        console.error('Error adding message to queue:', error)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    cleanQueue() {
      audioPlayerRef.current.cleanQueue()
    },
    skip() {
      audioPlayerRef.current.skip()
    },
    pause() {
      audioPlayerRef.current.pause()
    },
    playNext() {
      audioPlayerRef.current.playNext()
    },
    stop() {
      audioPlayerRef.current.stop()
    },
    resume() {
      audioPlayerRef.current.resume()
    },
    enqueue(audio) {
      setQueue((prevQueue) => {
        if (prevQueue === undefined || prevQueue.length === 0) {
          const updatedQueue = [...prevQueue, audio]
          return updatedQueue
        }
        const lastAudio = prevQueue[prevQueue.length - 1]
        console.log('lastAudio', lastAudio)
        console.log('audio', audio)
        if (
          lastAudio.id === audio.id
          /*  lastAudio &&
          lastAudio.text === audio.originalMessage.text &&
          lastAudio.userName == audio.originalMessage.userName */
        ) {
          console.log('This message is already the last in the queue.')
          return prevQueue
        } else {
          const updatedQueue = [...prevQueue, audio]
          return updatedQueue
        }
      })
      audioPlayerRef.current.enqueue(audio)
    },
  }))

  const enqueue = (audioUrl) => {
    audioPlayerRef.current.enqueue(audioUrl)
  }

  const cleanQueue = () => {
    audioPlayerRef.current.cleanQueue()
  }

  const loadQueue = async () => {
    try {
      cleanQueue()
      var data = await fetchQueueMessages(user)
      const messages = data.map((group) => group[0])
      console.log('messages', messages)
      let historyItemIds = data.flatMap((subArray) => subArray.map((item) => item))
      console.log('historyItemIds', historyItemIds)
      for (let i = 0; i < historyItemIds.length; i++) {
        const id = historyItemIds[i]
        console.log('id', id)

        enqueue(id)
      }

      setQueue(messages)
    } catch (error) {
      console.error('Failed to fetch queue messages:', error)
    }
  }

  return (
    <Card sx={{ maxHeight: '566px', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          paddingBottom: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Title icon={<IcoMessageQuede />} title='Message Queue' />
        <Button
          variant='contained'
          color='secondary'
          startIcon={<CachedOutlinedIcon />}
          onClick={loadQueue}
        >
          RELOAD
        </Button>
      </Box>
      <Box component='form' autoComplete='off' onSubmit={handleSubmit} sx={{ mb: '32px' }}>
        <TextField
          sx={{ m: '0' }}
          name='message'
          label='Ej. (voice) your message'
          margin='normal'
          variant='filled'
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          fullWidth
          sx={{ mt: '8px' }}
        >
          ADD MESSAGE TO QUEUE
        </Button>
      </Box>
      <Box sx={{ overflow: 'auto', flex: '1' }}>
        {queue.map((item, index) => (
          <TextField
            sx={{ mb: '4px' }}
            key={index}
            value={item.originalMessage.text}
            fullWidth
            label={item.originalMessage.userName}
            margin='normal'
            variant='filled'
            multiline
            InputProps={{
              readOnly: true,
            }}
          />
        ))}
      </Box>
      <AudioPlayer ref={audioPlayerRef} />
    </Card>
  )
})

export default MessageQueue
