export const IcoAlertSettings = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.083 22.917h20.834M21.875 16.698v-1.073A9.376 9.376 0 0012.5 6.25a9.33 9.33 0 00-4.167.98 9.37 9.37 0 00-5.208 8.396v7.29h18.75v-2.114M12.5 2.083v1.042M4.167 4.167l1.041 1.041M20.833 4.167l-1.041 1.041'
      ></path>
    </svg>
  )
}
