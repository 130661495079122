import React from 'react';

const Profile = () => {
  return (
    <div className="container">
      <h2>Perfil de Usuario</h2>
      {/* Contenido del Perfil */}
    </div>
  );
};

export default Profile;
