import { Stack, Typography } from '@mui/material'

const Title = ({ title, icon, content }) => {
  return (
    <>
      <Stack direction='row' spacing={2}>
        {icon}
        <Typography variant='h6' sx={{ fontWeight: '500' }}>
          {title}
        </Typography>
      </Stack>
      <Typography variant='paragraph02' component='p' sx={{ fontWeight: '300' }}>
        {content}
      </Typography>
    </>
  )
}

export default Title
