import { useNavigate } from 'react-router-dom'
import { Logout, PersonAdd } from '@mui/icons-material'
import { Avatar, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useUser } from '../../UserContext/UserContext'

const UserMenu = ({ anchorEl, setAnchorEl }) => {
  const navigate = useNavigate()
  const open = Boolean(anchorEl)

  const { user } = useUser()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    handleClose()
    localStorage.clear()
    navigate('/')
  }

  return (
    <Menu
      id='account-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#3B395F',
          padding: '15px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate('/profile')}>
        <Avatar
          sx={{ backgroundColor: '#85C577', marginRight: '8px' }}
          src={user?.profileImageUrl}
        />
        <ListItemText primary='My profile' secondary={`@ ${user?.displayName}`} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate('/create-moderator')}>
        <ListItemIcon>
          <PersonAdd fontSize='small' sx={{ color: '#85C577' }} />
        </ListItemIcon>
        Create moderator
      </MenuItem>
      <MenuItem onClick={() => logOut()}>
        <ListItemIcon>
          <Logout fontSize='small' sx={{ color: '#85C577' }} />
        </ListItemIcon>
        Log out
      </MenuItem>
    </Menu>
  )
}

export default UserMenu
