export const IcoAddModerator = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M12.5 12.5a5.208 5.208 0 100-10.417 5.208 5.208 0 000 10.417zM3.552 22.917c0-4.032 4.01-7.292 8.948-7.292 1 0 1.969.135 2.875.385'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M22.917 18.75c0 .333-.042.656-.125.969a3.89 3.89 0 01-.48 1.177 4.135 4.135 0 01-3.562 2.02 4.084 4.084 0 01-2.77-1.072 3.839 3.839 0 01-.793-.948 4.085 4.085 0 01-.604-2.146 4.161 4.161 0 014.167-4.167c1.23 0 2.344.532 3.094 1.386a4.15 4.15 0 011.073 2.781zM20.302 18.73h-3.104M18.75 17.208v3.115'
      ></path>
    </svg>
  )
}
