import { Switch as MuiSwitch } from '@mui/material'
import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'

const StyledSwitch = styled(MuiSwitch)(({ theme, ownerState }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: ownerState.onColor,
    '&:hover': {
      backgroundColor: `${ownerState.onColor}20`, // Adjust hover background color opacity
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: ownerState.onColor,
  },
}))

const Switch = ({ isOn, handleToggle, onColor, id }) => {
  return (
    <FormControlLabel
      control={
        <StyledSwitch
          checked={isOn}
          onChange={handleToggle}
          ownerState={{ onColor }}
          inputProps={{ 'aria-label': 'controlled' }}
          id={id}
        />
      }
      label={isOn ? 'Enabled' : 'Disabled'}
    />
  )
}

export default Switch
