import { Box, Typography } from '@mui/material'

const Footer = () => {
  return (
    <Box sx={{ mt: 16, pb: 4 }}>
      <Typography> © 2024 - ECOTTS</Typography>
    </Box>
  )
}

export default Footer
