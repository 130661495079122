import { Box, Button, Card, Grid } from '@mui/material'
import Title from '../ui/Title'
import {
  IcoAudioMute,
  IcoAudioNext,
  IcoAudioPause,
  IcoModeration,
  IcoRefresh,
  IcoSettings,
} from '../ui/icons'

const ModerationTool = ( {onSkip, onPause, onPlayNext, onStop, onResume} ) => {
  return (
    <Card>
      <Box
        sx={{
          paddingBottom: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Title icon={<IcoSettings />} title='Moderation Tools' />
      </Box>
      <Grid container spacing={3} sx={{ pb: '31px' }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant='contained'
            color='secondary'
            startIcon={<IcoAudioNext />}
            sx={{ height: '60px' }}
            onClick={onSkip}
          >
            Skip TTS
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ pb: '31px' }}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='contained'
            color='secondary'
            startIcon={<IcoAudioPause />}
            onClick={onPause}
          >
            Pause Queue
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button 
            fullWidth 
            variant='contained' 
            color='secondary' 
            startIcon={<IcoAudioNext />} 
            onClick={onResume}
          >
            Resume Queue
          </Button>
        </Grid>
      </Grid>

{/*       <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant='outlined' color='secondary' endIcon={<IcoModeration />}>
            Send Text To Moderation To Overlay
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant='outlined' color='secondary' endIcon={<IcoRefresh />}>
            TTS Not Working? Reload Overlay
          </Button>
        </Grid>
      </Grid> */}
    </Card>
  )
}

export default ModerationTool
