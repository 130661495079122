import React from 'react'
import Profile from '../components/Profile'
import Header from '../components/Header'

const ProfileView = () => {
  return (
    <>
      <Header />
      <Profile />
    </>
  )
}

export default ProfileView
