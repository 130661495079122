import React from 'react'

export const IcoDisabledVoices = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M2.083 13.542c0-2.76 1.198-4.323 3.125-4.917.625-.198 1.323-.292 2.084-.292H11.5m8.292.292c1.927.594 3.125 2.156 3.125 4.917v4.166c0 3.646-2.084 5.209-5.209 5.209H7.292m-5.209-5.209c0 .963.146 1.78.417 2.462'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M5.208 8.625V7.292a2.09 2.09 0 012.084-2.084h6.416M16.667 3.656c0-.864-.709-1.573-1.573-1.573H9.906c-.864 0-1.573.709-1.573 1.573v1.552H13.5'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M9.448 20.26a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75z'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15.885 17.969v-5.386c0-1.146-.718-1.312-1.447-1.104l-2.76.75c-.5.136-.845.531-.845 1.104v5.552'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M14.51 19.344a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zM10.833 14.948l5.052-1.375'
      ></path>
      <path stroke='#85C577' strokeLinecap='round' strokeWidth='1.5' d='M22 2L3 23.5'></path>
    </svg>
  )
}
