import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../issets/logo.png'
import UserMenu from './ui/UserMenu'
import { ListItemText, Avatar, Box, Grid } from '@mui/material'
import { useUser } from '../UserContext/UserContext'

const Header = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const { user } = useUser()

  const handleProfileClick = (event) => {
    event.preventDefault()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <header>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        p='25px'
      >
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>
          <img src={logo} alt='Logo' />
        </Box>
        <Box
          onClick={handleProfileClick}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <Avatar
            sx={{ backgroundColor: '#85C577', marginRight: '8px' }}
            src={user?.profileImageUrl}
          />
          <ListItemText primary='My menu' />
        </Box>
        <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </Grid>
    </header>
  )
}

export default Header
