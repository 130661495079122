import React, { createContext, useState, useContext, useEffect } from 'react'

const UserContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUserData = localStorage.getItem('user')
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          id: null,
          login: null,
          displayName: null,
          profileImageUrl: null,
          userType: null,
          accessToken: null,
          refreshToken: null,
        }
  })

  useEffect(() => {
    if (user && user.id) {
      localStorage.setItem('user', JSON.stringify(user))
    }
  }, [user])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
