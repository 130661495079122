export const IcoEnabledVoices = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M2.083 13.542c0-2.76 1.198-4.323 3.125-4.917.625-.198 1.323-.292 2.084-.292h10.416c.76 0 1.459.094 2.084.292 1.927.594 3.125 2.156 3.125 4.917v4.166c0 3.646-2.084 5.209-5.209 5.209H7.292c-3.125 0-5.209-1.563-5.209-5.209'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M19.792 7.292v1.333c-.625-.198-1.323-.292-2.084-.292H7.292c-.76 0-1.459.094-2.084.292V7.292a2.09 2.09 0 012.084-2.084h10.416a2.09 2.09 0 012.084 2.084zM16.667 3.656v1.552H8.333V3.656c0-.864.709-1.573 1.573-1.573h5.188c.864 0 1.573.709 1.573 1.573z'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M9.448 20.26a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75z'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15.885 17.969v-5.386c0-1.146-.718-1.312-1.447-1.104l-2.76.75c-.5.136-.845.531-.845 1.104v5.552'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M14.51 19.344a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zM10.833 14.948l5.052-1.375'
      ></path>
    </svg>
  )
}
