import { Box, Button, Card, Grid, Stack, TextField } from '@mui/material'
import { IcoAddModerator } from '../ui/icons'
import Title from '../ui/Title'
import { useUser } from '../../UserContext/UserContext'
import { fetchCreateModerator } from '../../data/apiService'
import { useState } from 'react'

const CreateModerator = () => {
  const { user } = useUser()
  const [moderatorName, setModeratorName] = useState('')

  const createModerator = async () => {
    try {
      await fetchCreateModerator(user, moderatorName)
      console.log('Enviar', moderatorName)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container display='flex' justifyContent='center'>
      <Card>
        <Title
          icon={<IcoAddModerator />}
          title='Create moderator'
          content='Create moderators to monitor and manage interactions.'
        />

        <Box component='form' autoComplete='off' sx={{ mt: '46px' }}>
          <Stack direction='column' alignItems='center' spacing={4}>
            <TextField
              fullWidth
              type='text'
              size='small'
              label='Enter moderator name (twitch username)'
              variant='filled'
              required
              onChange={(e) => setModeratorName(e.target.value)}
            />

            <Button variant='contained' fullWidth color='secondary' onClick={createModerator}>
              CREATE MODERATOR
            </Button>
          </Stack>
        </Box>
      </Card>
    </Grid>
  )
}

export default CreateModerator
