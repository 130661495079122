import CreateModerator from '../components/CreateModerator/CreateModerator'
import Header from '../components/Header'

const CreateModeratorView = () => {
  return (
    <>
      <Header />
      <CreateModerator />
    </>
  )
}

export default CreateModeratorView
