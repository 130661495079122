export const IcoMessageQuede = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.083 13.52v2.105c0 5.208 2.084 7.292 7.292 7.292h6.25c5.208 0 7.292-2.084 7.292-7.292v-6.25c0-5.208-2.084-7.292-7.292-7.292h-6.25c-5.208 0-7.292 2.084-7.292 7.292M16.406 9.375H8.594M16.406 15.625H8.594'
      ></path>
    </svg>
  )
}
