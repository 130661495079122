// DashboardView.js
import React from 'react'
import Dashboard from '../components/Dashboard'
import 'bootstrap/dist/css/bootstrap.min.css' // Importa los estilos de Bootstrap
import '../css/Dashboard.css'
import Header from '../components/Header'

const DashboardView = () => {
  return (
    <>
      <Header />
      <Dashboard />
    </>
  )
}

export default DashboardView
