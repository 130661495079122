import { FaTwitch } from 'react-icons/fa'
import '../css/Login.css'
import logo from '../issets/logo-home.png'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

const Login = () => {
  const handleLogin = async () => {
    try {
      const authUrl = `http://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user:read:email`
      window.location.href = authUrl
    } catch (error) {
      console.error('Error al iniciar sesión con Twitch:', error)
    }
  }

  return (
    <div className='login-container'>
      <div className='login-form-container'>
        <div className='login-form-header'>
          <img src={logo} alt='Logo' className='logo' />
        </div>
        <div className='login-form-content'>
          <button className='login-btn' onClick={handleLogin}>
            <FaTwitch style={{ marginRight: '5px' }} />
            Iniciar sesión con Twitch
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login
