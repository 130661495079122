import { createTheme } from '@mui/material/styles'
import components from './ComponentOverRide'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3B395F',
      dark: '#2D2A46',
      light: '#9190BC',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#85C577',
      dark: '#55884A',
      light: '#CAE5C4',
      contrastText: '#2D2A46',
    },
    tertiary: {
      main: '#9F9BDE',
      dark: '#DCE1E9',
      light: '#F3F6FA',
    },
    text: {
      primary: '#ECF0F5',
      secondary: '#85C577',
      disabled: '#171527',
      hint: '#85C577',
    },
    error: {
      main: '#E95A5A',
      dark: '#B94040',
      light: '#EC7979',
    },
    success: {
      main: '#6CC227',
      dark: '#308A06',
      light: '#B9E199',
    },
    info: {
      main: '#3F87DD',
      dark: '#1A5294',
      light: '#84BCFF',
    },
    divider: '#9190BC',
    background: {
      default: '#2D2A53',
    },
  },
  direction: 'rtl',
  shape: {
    borderRadius: 4,
  },

  typography: {
    fontFamily: "'Inter', sans-serif",
    h4: {
      fontFamily: 'Inter',
      fontSize: '1.956rem',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    paragraph01: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 400,
    },
    paragraph02: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontWeight: 400,
    },
  },
  spacing: 8,
  components,
})

export default theme
