const components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        backgroundColor: '#2D2A46',
        height: '100%',
        margin: 0,
        padding: 0,
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      '#root': {
        height: '100%',
      },

      '.MuiFilledInput-root': {
        '&:before': {
          borderBottom: '1px solid #53af5b61 !important;',
        },
        '&:after': {
          borderBottom: '2px solid #53af5b !important',
        },
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: '600',
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: '#CAE5C4',
        },
      },
    },
  },

  /* MuiButtonOutlined: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  }, */

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        backgroundColor: '#3B395F',
        border: '1px solid #3B395F',
        padding: '40px',
        margin: '20px 0',
        boxShadow: '3px 4px 12px rgba(0,0,0,0.08)',
      },
    },
  },

  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: '#2D2A46',
        '&:hover:not(.Mui-disabled, .Mui-error)': {
          backgroundColor: '#25223C',
        },
        '&.Mui-focused': {
          backgroundColor: '#25223C',
        },
        '&.Mui-disabled': {
          backgroundColor: '#B1B0D3',
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: '#85C577',
        '&.Mui-focused': {
          color: '#85C577',
        },
      },
    },
  },

  MuiFormControl: {
    styleOverrides: {
      root: {
        margin: '0 0 35px',
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#9190BC',
        '&.Mui-checked': {
          color: '#85C577',
        },
      },
    },
  },
}

export default components
