export const IcoAudioNext = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='#2D2A46'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M3.76 7.22v9.57c0 1.96 2.13 3.19 3.83 2.21l4.15-2.39 4.15-2.4c1.7-.98 1.7-3.43 0-4.41l-4.15-2.4-4.15-2.39c-1.7-.98-3.83.24-3.83 2.21zM20.24 18.18V5.82'
      ></path>
    </svg>
  )
}
