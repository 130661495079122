export const IcoDashboard = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='34'
      fill='none'
      viewBox='0 0 34 34'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M31.167 15.442V5.808c0-2.125-.907-2.975-3.16-2.975h-5.723c-2.252 0-3.159.85-3.159 2.975v9.634c0 2.125.907 2.975 3.16 2.975h5.723c2.252 0 3.159-.85 3.159-2.975zM31.167 28.192v-2.55c0-2.125-.907-2.975-3.16-2.975h-5.723c-2.252 0-3.159.85-3.159 2.975v2.55c0 2.125.907 2.975 3.16 2.975h5.723c2.252 0 3.159-.85 3.159-2.975zM14.875 18.558v9.634c0 2.125-.907 2.975-3.16 2.975H5.993c-2.252 0-3.159-.85-3.159-2.975v-9.634c0-2.125.907-2.975 3.16-2.975h5.723c2.252 0 3.159.85 3.159 2.975zM14.875 5.808v2.55c0 2.125-.907 2.975-3.16 2.975H5.993c-2.252 0-3.159-.85-3.159-2.975v-2.55c0-2.125.907-2.975 3.16-2.975h5.723c2.252 0 3.159.85 3.159 2.975z'
      ></path>
    </svg>
  )
}
