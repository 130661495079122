export const IcoSettings = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      fill='none'
      viewBox='0 0 25 25'
    >
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M9.375 22.917h6.25c5.208 0 7.292-2.084 7.292-7.292v-6.25c0-5.208-2.084-7.292-7.292-7.292h-6.25c-5.208 0-7.292 2.084-7.292 7.292v6.25c0 5.208 2.084 7.292 7.292 7.292z'
      ></path>
      <path
        stroke='#85C577'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M16.219 19.27v-4.062M16.219 7.76V5.73M16.219 13.177a2.708 2.708 0 100-5.417 2.708 2.708 0 000 5.417zM8.781 19.27v-2.03M8.781 9.792V5.729M8.781 17.24a2.708 2.708 0 100-5.417 2.708 2.708 0 000 5.416z'
      ></path>
    </svg>
  )
}
