export const IcoBits = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='35'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='2'
        d='M19.836 9.18V13m0 10v7.164M19.836 3.279L6.557 26.078 19.837 36.6M23.5 34l9.615-7.922L23.5 9M12 23.5l7.836-6.191L27.5 23.5'
      ></path>
    </svg>
  )
}
